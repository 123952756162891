export const HeaderComponent = () => {
  return (
        <div className="header__component">
            <img
                src= {'../assets/logo/logo-02.png'}
            />
        </div>
    
      
  )
};
