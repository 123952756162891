import React from 'react'

export const Oferta = () => {
    return (
        <>
            <div className='container__oferta'>
                <div className='box__uno'>
                    <h3> <spam> transforma </spam>  <br/>hoy tus espacios <br/>con el</h3>
                    
                </div>
                <div className='box__dos'>
                    <h4>10%</h4>
                    <p>de descuento</p>
                </div>

                <div className='btn__oferta'>
                    <a target="_blank" href="https://api.whatsapp.com/message/QWPGHL6ANV7MD1?autoload=1&app_absent=0">Cotizar</a>
                </div>
                
            </div>
        </>
    )
}
